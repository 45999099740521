import { turnActions } from "./actions";
import { Player, TurnState } from "./types";

export const defaultState = {
  roller: Player.Two,
  rolled: [0, 0],
  availableRolls: []
};

export const initConfig = (state: TurnState): TurnState => ({
  ...defaultState,
  ...state
});

export const turnReducer = (state: TurnState, action: any /*todo fix?*/) => {
  if (action.type === turnActions.roll) {
    const { roller, roll1, roll2 } = action.payload;

    // start with highest number first
    const availableRolls = [
      { rolled: Math.max(roll1, roll2), used: false },
      { rolled: Math.min(roll1, roll2), used: false }
    ];

    // add the doubles
    if (roll1 === roll2) {
      availableRolls.push({ rolled: roll1, used: false }, { rolled: roll1, used: false });
    }

    return {
      roller,
      rolled: [roll1, roll2],
      availableRolls
    };
  }

  if (action.type === turnActions.moveDice) {
    const rollUnused = state.availableRolls.find((ar) => !ar.used);

    return {
      ...state,
      availableRolls: rollUnused ? [...state.availableRolls].reverse() : []
    };
  }

  if (action.type === turnActions.updateAvailableRolls) {
    const { rollUsed } = action.payload;
    const newAvailableRolls = [...state.availableRolls];
    const index = newAvailableRolls.findIndex((r) => r.rolled === rollUsed && !r.used);

    if (index < 0) return state;

    newAvailableRolls[index] = {
      rolled: rollUsed,
      used: true
    };

    return {
      ...state,
      availableRolls: newAvailableRolls
    };
  }

  return state;
};
