// import { Checker, Board, Match } from "./OOP";
// const board = new Board();
// const match = new Match(9, board);
//console.log(board);
// Board
// --Pipcount
// --Point
// ----Checker
// --Cube
// --Dice
// --Bar
// --BearOffTray
export const data = {
  match: {
    playerTurn: 1,
    roll: 9,
    cube: 0,
    player1: {
      name: "Matt",
      checkerColour: "#FFFFFF",
      points: 3
    },
    player2: {
      name: "Betsy",
      checkerColour: "#000000",
      points: 5
    },
    board: {
      bar: {
        p1CheckerCount: 0,
        p2CheckerCount: 0
      },
      bearOffTray: {
        p1CheckerCount: 0,
        p2CheckerCount: 0
      },
      points: [
        {
          id: 1,
          owner: 2,
          checkerCount: 2
        },
        {
          id: 2,
          checkerCount: 0
        },
        {
          id: 3,
          checkerCount: 0
        },
        { id: 4, checkerCount: 0 },
        { id: 5, checkerCount: 0 },
        { id: 6, owner: 1, checkerCount: 5 },
        {
          id: 7,
          checkerCount: 0
        },
        { id: 8, owner: 1, checkerCount: 3 },
        { id: 9, checkerCount: 0 },
        { id: 10, checkerCount: 0 },
        { id: 11, checkerCount: 0 },
        {
          id: 12,
          owner: 2,
          checkerCount: 5
        },
        {
          id: 13,
          owner: 1,
          checkerCount: 5
        },
        { id: 14, checkerCount: 0 },
        { id: 15, checkerCount: 0 },
        { id: 16, checkerCount: 0 },
        {
          id: 17,
          owner: 2,
          checkerCount: 3
        },
        { id: 18, checkerCount: 0 },
        {
          id: 19,
          owner: 2,
          checkerCount: 5
        },
        { id: 20, checkerCount: 0 },
        { id: 21, checkerCount: 0 },
        { id: 22, checkerCount: 0 },
        { id: 23, checkerCount: 0 },
        {
          id: 24,
          owner: 1,
          checkerCount: 2
        }
      ]
    }
  }
};
