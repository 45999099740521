import { useState, useReducer, MouseEvent } from "react";
import "./App2.css";
import { data } from "./data";
//import { data } from "./bearoff";
//import { data } from "./bearIn";
import {
  Player,
  BarState,
  BearOffTrayState,
  DiceState,
  PlayerMatchState,
  CheckerState,
  TBoardPoint,
  BoardPointState,
  PointStatus,
  TurnState
} from "./types";
import { turnReducer, initConfig, defaultState } from "./reducers";
import { turnActions } from "./actions";

function Checker({ id, colour }: CheckerState) {
  return <div id={id} className="checker" style={{ backgroundColor: colour }}></div>;
}

// function BoardPoint({ id, checkerCount, checkerColour, direction, owner, onClick }: TBoardPoint) {
//   const checkers = Array.from({ length: checkerCount }).map((_c, i) => (
//     <Checker id={`p${id}-c${i + 1}`} key={`p${id}-c{${i + 1}}`} colour={checkerColour} />
//   ));
//   return (
//     <div
//       id={id.toString()}
//       className={`point-${direction}`}
//       onClick={(e) => onClick(parseInt(e.currentTarget.id))}
//     >
//       {checkerCount > 0 ? <>{checkers}</> : <></>}
//       {id}
//     </div>
//   );
// }

function BoardPoint2({ id, checkerCount, checkerColour, onClick }: TBoardPoint) {
  if (checkerCount === 0)
    return (
      <div id={`p${id}`} className="point">
        {/* {id} */}
      </div>
    );

  const checkers = Array.from({ length: checkerCount }).map((_c, i) => (
    <Checker id={`p${id}-c${i + 1}`} key={`p${id}-c{${i + 1}}`} colour={checkerColour} />
  ));

  return (
    <div
      id={`p${id}`}
      className="point"
      onClick={(e) => onClick(parseInt(e.currentTarget.id.split("p")[1]))}
    >
      {/* {id} */}
      {checkers.slice(0, 5)}
      {checkerCount > 5 && <div className="point-l2">{checkers.slice(5, 9)}</div>}
      {checkerCount > 9 && <div className="point-l3">{checkers.slice(10, 12)}</div>}
      {checkerCount > 12 && <div className="point-l4">{checkers.slice(12, 14)}</div>}
      {checkerCount > 14 && <div className="point-l5">{checkers.slice(14)}</div>}
    </div>
  );
}

function Dice({ rolls, onSwitch }: DiceState) {
  return (
    <div className="dice">
      {rolls.map((r, i) => (
        <div
          key={`roll-${i + 1}`}
          className={`die${r.used ? "-disabled" : ""}`}
          onClick={() => onSwitch()}
        >
          {r.rolled}
        </div>
      ))}
    </div>
  );
}

function Board() {
  const [turn, dispatch] = useReducer(turnReducer, defaultState);
  const [boardPoints, setBoardPoints] = useState<BoardPointState[]>([...data.match.board.points]);
  const [bar, setBar] = useState<BarState>({ ...data.match.board.bar });
  const [players, setPlayers] = useState<{ one: PlayerMatchState; two: PlayerMatchState }>({
    one: data.match.player1,
    two: data.match.player2
  });
  const [bearOfTray, setBearOffTray] = useState<BearOffTrayState>({
    ...data.match.board.bearOffTray
  });

  function rollDice() {
    return 1 + Math.floor(Math.random() * 6);
  }
  const handleRoll = (roller: Player) => {
    console.log("Roller: ", roller);
    const roll1 = rollDice();
    const roll2 = rollDice();

    dispatch({ type: turnActions.roll, payload: { roller, roll1, roll2 } });

    const availableMoves = getAllMovesForRoll(roller, roll1, roll2);

    if (
      (roller === Player.One && bar.p1CheckerCount > 0) ||
      (roller === Player.Two && bar.p2CheckerCount > 0)
    ) {
      if (canBearIn(roller, roll1, roll2)) {
      }
    }

    console.log("Available moves: ", availableMoves);
  };

  const handleMoveDice = () => {
    dispatch({ type: turnActions.moveDice });
  };

  const canBearOff = (playerTurn: Player, boardPoints: BoardPointState[]): boolean => {
    let innerBoardCheckerCount = 0;
    const borneOff =
      playerTurn === Player.One ? bearOfTray.p1CheckerCount : bearOfTray.p2CheckerCount;

    // only need to check if all 15 checkers are in the inner boards
    for (let i = 0; i < 6; i++) {
      const bp = boardPoints[playerTurn === Player.One ? i : 18 + i]; // player 2 inner board starts at 19
      if (bp.owner !== playerTurn) continue;
      innerBoardCheckerCount = innerBoardCheckerCount + bp.checkerCount;
    }

    return innerBoardCheckerCount + borneOff === 15;
  };

  function getPointStatus(roller: Player, to: BoardPointState): PointStatus {
    if (!to) return PointStatus.PointOffBoard;
    if (!to.owner) return PointStatus.EmptyPoint;
    if (to.owner !== roller && to.checkerCount === 1) return PointStatus.OpponentBlot;
    if (to.owner === roller && to.checkerCount === 1) return PointStatus.OwnBlot;
    if (to.owner !== roller && to.checkerCount > 1) return PointStatus.OpponentPoint;
    if (to.owner === roller && to.checkerCount > 1) return PointStatus.OwnPoint;
    return PointStatus.Unknown;
  }

  function getAllMovesForRoll(roller: Player, rollOne: number, rollTwo: number) {
    const pointsOn = boardPoints.filter((bp) => bp.owner === roller);

    return pointsOn.flatMap((p) => {
      const combos = getMovesForRoll(roller, p.id, rollOne, rollTwo);
      combos.push(...getMovesForRoll(roller, p.id, rollTwo, rollOne)); // also need to check when roll is reversed
      return combos;
    });
  }

  function getBarMoves(roller: Player, rollOne: number, rollTwo: number) {
    let availableMoves = {
      rollOne: {
        canEnter: false,
        canContinueWithRollTwo: false,
        canContinueWithRollThree: false,
        canContinueWithRollFour: false
      },
      rollTwo: {
        canEnter: false,
        canContinueWithRollOne: false,
        canContinueWithRollThree: false,
        canContinueWithRollFour: false
      }
    };
    const checkerIndex = (roller: Player, rolled: number) =>
      roller === Player.One ? 25 - rolled - 1 : 0 + rolled - 1;

    const bpRollOne = boardPoints[checkerIndex(roller, rollOne)];
    const rollOnePointStatus = getPointStatus(roller, bpRollOne);

    const bpRollTwo = boardPoints[checkerIndex(roller, rollTwo)];
    const rollTwoPointStatus = getPointStatus(roller, bpRollTwo);

    if (rollOnePointStatus !== PointStatus.OpponentPoint) {
      availableMoves.rollOne.canEnter = true;
      const rollOneContinueStatus = getPointStatus(
        roller,
        boardPoints[checkerIndex(roller, rollOne + rollTwo)]
      );
      availableMoves.rollOne.canContinueWithRollTwo =
        rollOneContinueStatus !== PointStatus.OpponentPoint;

      if (rollOne === rollTwo) {
        const rollThreeContinueStatus = getPointStatus(
          roller,
          boardPoints[checkerIndex(roller, rollOne * 3)]
        );
        if (rollThreeContinueStatus !== PointStatus.OpponentPoint) {
          availableMoves.rollOne.canContinueWithRollThree = true;
          const rollFourContinueStatus = getPointStatus(
            roller,
            boardPoints[checkerIndex(roller, rollOne * 4)]
          );
          availableMoves.rollOne.canContinueWithRollFour =
            rollFourContinueStatus !== PointStatus.OpponentPoint;
        }
      }
    }

    if (rollTwoPointStatus !== PointStatus.OpponentPoint) {
      availableMoves.rollTwo.canEnter = true;
      const rollTwoContinueStatus = getPointStatus(
        roller,
        boardPoints[checkerIndex(roller, rollOne + rollTwo)]
      );
      availableMoves.rollOne.canContinueWithRollTwo =
        rollTwoContinueStatus !== PointStatus.OpponentPoint;
    }
    return availableMoves;
  }

  function canMoveToPoint(status: PointStatus): boolean {
    return (
      status === PointStatus.EmptyPoint ||
      status === PointStatus.OpponentBlot ||
      status === PointStatus.OwnBlot ||
      status === PointStatus.OwnPoint
    );
  }

  function getMovesForRoll(roller: Player, from: number, rollOne: number, rollTwo: number) {
    // console.log("Roller: ", roller);
    const rollsForMoves: any[] = []; // fix

    const toRollOne = roller === Player.One ? from - rollOne : from + rollOne;
    // continued from one
    const toRollTwo = roller === Player.One ? toRollOne - rollTwo : toRollOne + rollTwo;

    // console.log(`(${rollOne}) from: ${from}, to: ${toRollOne}`);
    // console.log(`(${rollTwo}) from: ${toRollOne}, to: ${toRollTwo}`);

    const toPointRollOne = boardPoints[toRollOne - 1];
    // console.log("To point roll 1: ", toPointRollOne);
    const toPointRollTwo = boardPoints[toRollTwo - 1];
    // console.log("To point roll 2: ", toPointRollTwo);

    const rollOnePointStatus = getPointStatus(roller, toPointRollOne);
    // console.log("Roll 1 point status", rollOnePointStatus);
    const canMoveRollOne = canMoveToPoint(rollOnePointStatus);

    let canContinueWithRollTwo = false;
    let rollTwoPointStatus = PointStatus.Unknown;

    if (canMoveRollOne) {
      rollTwoPointStatus = getPointStatus(roller, toPointRollTwo);
      // console.log("Roll 2 point status", rollTwoPointStatus);
      canContinueWithRollTwo = canMoveToPoint(rollTwoPointStatus);
      rollsForMoves.push({
        roll: rollOne,
        from,
        to: toRollOne,
        pointStatus: PointStatus[rollOnePointStatus]
      });
    }

    // console.log(`(${rollOne}) ${from} to ${toRollOne} : ${canMoveRollOne}`);
    // console.log(`(${rollTwo}) ${toRollOne} to ${toRollTwo} : ${canContinueWithRollTwo}`);

    if (canContinueWithRollTwo) {
      rollsForMoves.push({
        roll: rollTwo,
        from: toRollOne,
        to: toRollTwo,
        continuation: true,
        pointStatus: PointStatus[rollTwoPointStatus]
      });
    }

    // Doubles
    if (canContinueWithRollTwo && rollOne === rollTwo) {
      const toRollThree = roller === Player.One ? toRollTwo - rollOne : toRollTwo + rollOne;
      const toRollFour = roller === Player.One ? toRollThree - rollOne : toRollThree + rollOne;

      const toPointRollThree = boardPoints[toRollThree - 1];
      // console.log("To point roll 3: ", toPointRollThree);
      const toPointRollFour = boardPoints[toRollFour - 1];
      // console.log("To point roll 4: ", toPointRollFour);

      const rollThreePointStatus = getPointStatus(roller, toPointRollThree);
      // console.log("Roll 3 point status", rollThreePointStatus);
      const canContinueWithRollThree = canMoveToPoint(rollThreePointStatus);

      let canContinueWithRollFour = false;
      let rollFourPointStatus = PointStatus.Unknown;

      if (canContinueWithRollThree) {
        rollFourPointStatus = getPointStatus(roller, toPointRollFour);
        // console.log("Roll 4 point status", rollThreePointStatus);
        canContinueWithRollFour = canMoveToPoint(rollFourPointStatus);
        rollsForMoves.push({
          roll: rollOne,
          from: toRollTwo,
          to: toRollThree,
          continuation: true,
          pointStatus: PointStatus[rollThreePointStatus]
        });
      }

      if (canContinueWithRollFour) {
        rollsForMoves.push({
          roll: rollOne, // doubles so doesn't matter
          from: toRollThree,
          to: toRollFour,
          continuation: true,
          pointStatus: PointStatus[rollFourPointStatus]
        });
      }
    }

    return rollsForMoves;
  }

  const handleMove = (from: number, rolled: number) => {
    if (turn.roller === Player.One && bar.p1CheckerCount > 0) {
      console.log(`${turn.roller} has a checker on the bar!`);
      return;
    }

    if (turn.roller === Player.Two && bar.p2CheckerCount > 0) {
      console.log(`${turn.roller} has a checker on the bar!`);
      return;
    }

    // from user's viewpoint player moves backwards to point 1, opponent moves forward to point 24
    const to = turn.roller === Player.One ? from - rolled : from + rolled;

    const fromIndex = from - 1;
    const toIndex = to - 1;

    const fromPoint = boardPoints[fromIndex];
    const toPoint = boardPoints[toIndex];

    if (fromPoint.owner !== turn.roller) {
      console.log(`Player ${turn.roller} can't move ${fromPoint.owner}'s checkers!`);
      return;
    }

    const isBearOff = canBearOff(turn.roller, boardPoints);

    const isRollOffBoard = (roller: Player, to: number): boolean => {
      return roller === Player.One ? to < 1 : to > 24;
    };

    const areHigherCheckers = (roller: Player, from: number, rolled: number) => {
      // if there are checkers on the rolled point don't need to worry about higher checkers first
      const rollPoint = boardPoints[roller === Player.One ? rolled - 1 : 24 - rolled];
      if (rollPoint.checkerCount > 0 && rollPoint.id === from) return false;

      for (let i = 0; i < 6; i++) {
        const bp = boardPoints[roller == Player.One ? 5 - i : 18 + i];
        if (bp.checkerCount > 0) {
          if (roller === Player.One) {
            if (bp.id > from) return true;
          } else {
            if (bp.id < from) return true;
          }
        }
      }
      return false;
    };

    // console.log("Can bear off:", turn.roller, isBearOff);

    if (isBearOff && isRollOffBoard(turn.roller, to)) {
      // can only bear off checkers if there aren't any on higher points
      const higherCheckers = areHigherCheckers(turn.roller, from, rolled);

      if (higherCheckers) {
        console.log("Must bear off higher checkers first");
        return;
      }

      setBoardPoints((s) => {
        const points = [...s];
        const fromNewCheckerCount = s[fromIndex].checkerCount - 1;
        points[fromIndex] = {
          ...s[fromIndex],
          checkerCount: fromNewCheckerCount,
          owner: fromNewCheckerCount < 1 ? undefined : turn.roller
        };
        return points;
      });

      setBearOffTray((s) => {
        const newBearOffTray = { ...s };
        if (turn.roller === Player.One) {
          newBearOffTray.p1CheckerCount = s.p1CheckerCount + 1;
        }
        if (turn.roller === Player.Two) {
          newBearOffTray.p2CheckerCount = s.p2CheckerCount + 1;
        }
        return newBearOffTray;
      });

      updateAvailableRolls(rolled);
      return;
    }

    if (toIndex < 0 || toIndex > 23) {
      console.log(`${turn.roller} can't bear off yet!`);
      return;
    }

    let isHit = false;

    if (toPoint.owner && toPoint.owner !== fromPoint.owner && toPoint.checkerCount === 1) {
      isHit = true;
    }

    if (toPoint.owner && toPoint.owner !== fromPoint.owner && toPoint.checkerCount > 1) {
      console.log(`${toPoint.owner} owns that point!`);
      handleMoveDice();
      return;
    }

    //WIP
    handleAnimateChecker(fromPoint, toPoint);

    setTimeout(() => {
      setBoardPoints((s) => {
        const points = [...s];
        const fromNewCheckerCount = s[fromIndex].checkerCount - 1;
        points[fromIndex] = {
          ...s[fromIndex],
          checkerCount: fromNewCheckerCount,
          owner: fromNewCheckerCount < 1 ? undefined : turn.roller
        };
        points[toIndex] = {
          ...s[toIndex],
          checkerCount: isHit ? s[toIndex].checkerCount : s[toIndex].checkerCount + 1,
          owner: turn.roller
        };
        return points;
      });
    }, 500);

    if (isHit) {
      setBar((s) => {
        const newBar = {
          ...s
        };
        if (turn.roller === Player.One) newBar.p2CheckerCount = s.p2CheckerCount + 1;
        if (turn.roller === Player.Two) newBar.p1CheckerCount = s.p1CheckerCount + 1;
        return newBar;
      });
    }

    updateAvailableRolls(rolled);
  };

  function canBearAllIn(turn: TurnState): boolean {
    const { roller, rolled } = turn;
    const checkersOnBar = roller === Player.One ? bar.p1CheckerCount : bar.p2CheckerCount;
    let canBearInOnePrev = true;
    let canBearInTwoPrev = true;

    for (let i = 0; i < checkersOnBar; i++) {
      if (!canBearInOnePrev || !canBearInTwoPrev) {
        return false;
      }

      const canBearInOne = canBearCheckerIn(roller, rolled![0]);
      const canBearInTwo = canBearCheckerIn(roller, rolled![1]);

      if (canBearInOne === false && canBearInTwo === false) {
        return false;
      }
      canBearInOnePrev = canBearInOne;
      canBearInTwoPrev = canBearInTwo;
    }

    return true;
  }

  function canBearCheckerIn(roller: Player, rolled: number): boolean {
    const checkerIndex = (roller: Player, rolled: number) =>
      roller === Player.One ? 25 - rolled - 1 : 0 + rolled - 1;

    const bp = boardPoints[checkerIndex(roller, rolled)];
    return bp.owner === undefined || turn.roller === bp.owner;
  }

  function canBearIn(roller: Player, roll1: number, roll2: number): boolean {
    const checkerIndex = (roller: Player, rolled: number) =>
      roller === Player.One ? 25 - rolled - 1 : 0 + rolled - 1;

    const bpRollOne = boardPoints[checkerIndex(roller, roll1)];
    console.log(`Roll 1 (${roll1}) bp: `, bpRollOne);

    const bpRollTwo = boardPoints[checkerIndex(roller, roll2)];
    console.log(`Roll 2 (${roll2}) bp: `, bpRollTwo);

    const canBearInRollOne =
      bpRollOne.owner === undefined || roller === bpRollOne.owner || bpRollOne.checkerCount < 2;
    console.log("Can bear in roll one: ", canBearInRollOne);

    // double
    if (!canBearInRollOne && roll1 === roll2) {
      // TODO update to update multiple at once
      updateAvailableRolls(roll1);
      updateAvailableRolls(roll1);
      updateAvailableRolls(roll1);
      updateAvailableRolls(roll1);
      return false;
    }

    const canBearInRollTwo =
      bpRollTwo.owner === undefined || roller === bpRollTwo.owner || bpRollTwo.checkerCount < 2;
    console.log("Can bear in roll two: ", canBearInRollTwo);

    if (!canBearInRollOne && !canBearInRollTwo) {
      updateAvailableRolls(roll1);
      updateAvailableRolls(roll2);
      return false;
    }
    return true;
  }

  const handleBarMove = (rolled: number) => {
    console.log("Player Turn: ", turn.roller);
    console.log("rolled: ", rolled);

    if (bar.p1CheckerCount === 0 && bar.p2CheckerCount === 0) {
      return; // No checkers on bar
    }

    // if (!canBearIn(turn)) {
    //   console.log("Can't bear in!");
    //   updateAvailableRolls(turn.rolled![0]);
    //   updateAvailableRolls(turn.rolled![1]);
    //   return;
    // }

    const to = turn.roller === Player.One ? 25 - rolled : 0 + rolled;
    console.log("To: ", to);

    const toIndex = to - 1;
    console.log("To Index: ", toIndex);

    const toPoint = boardPoints[toIndex];

    if (toIndex < 0 || toIndex > 23) {
      console.log(`${turn.roller} can't bear off yet!`);
      return;
    }

    let isHit = false;

    if (toPoint.owner && toPoint.owner !== turn.roller && toPoint.checkerCount === 1) {
      isHit = true;
    }

    if (toPoint.owner && toPoint.owner !== turn.roller && toPoint.checkerCount > 1) {
      console.log(`${toPoint.owner} owns that point!`);
      handleMoveDice();
      return;
    }

    setBoardPoints((s) => {
      const points = [...s];
      points[toIndex] = {
        ...s[toIndex],
        checkerCount: isHit ? s[toIndex].checkerCount : s[toIndex].checkerCount + 1,
        owner: turn.roller
      };
      return points;
    });

    setBar((s) => {
      const newBar = {
        ...s
      };
      if (turn.roller === Player.Two) {
        if (isHit) {
          newBar.p1CheckerCount = s.p1CheckerCount + 1;
        }
        newBar.p2CheckerCount = s.p2CheckerCount - 1;
      }
      if (turn.roller === Player.One) {
        if (isHit) {
          newBar.p2CheckerCount = s.p2CheckerCount + 1;
        }
        newBar.p1CheckerCount = s.p1CheckerCount - 1;
      }
      return newBar;
    });

    updateAvailableRolls(rolled);
  };

  function getNextAvailableRoll() {
    return turn.availableRolls.find((r) => !r.used);
  }

  function updateAvailableRolls(rollUsed: number) {
    dispatch({ type: turnActions.updateAvailableRolls, payload: { rollUsed } });
  }

  function handleAnimateChecker(fromPoint: BoardPointState, toPoint: BoardPointState): void {
    const { checkerCount: fromCheckerCount, id: fromId, owner: fromOwner } = fromPoint;
    const { checkerCount: toCheckerCount, id: toId, owner: toOwner } = toPoint;

    const isToEmptyPoint = toCheckerCount === 0;

    // with the up points checker 1 is always at the top
    const fromCheckerId = `p${fromId}-c${fromId < 13 ? 1 : fromCheckerCount}`;
    const fromCheckerEle = document.getElementById(fromCheckerId);

    const {
      top: fromTop,
      left: fromLeft,
      bottom: fromBottom
    } = fromCheckerEle!.getBoundingClientRect();

    const checkerHeight = fromBottom - fromTop;

    const toEleId = isToEmptyPoint ? `p${toId}` : `p${toId}-c${toId < 13 ? 1 : toCheckerCount}`;

    const {
      top: toTop,
      left: toLeft,
      bottom: toBottom
    } = document.getElementById(toEleId)!.getBoundingClientRect();

    let topEnd = isToEmptyPoint ? 0 : toTop - fromTop;
    let leftEnd = toLeft - fromLeft;

    if (toId < 13) {
      if (isToEmptyPoint) {
        topEnd = toBottom - fromTop - checkerHeight;
      } else {
        // if it's an opponent blot move it over (take) the checker, not above it
        topEnd = toCheckerCount === 1 && toOwner !== fromOwner ? topEnd : topEnd - checkerHeight;
      }
    } else {
      if (isToEmptyPoint) {
        topEnd = toTop - fromTop;
      } else {
        topEnd = toCheckerCount === 1 && toOwner !== fromOwner ? topEnd : topEnd + checkerHeight;
      }
    }

    fromCheckerEle!.style.zIndex = "1";
    fromCheckerEle!.animate([{ transform: `translate(${leftEnd}px, ${topEnd}px)` }], {
      duration: 500,
      easing: "ease-out"
    });
  }

  return (
    <div className="container">
      <div className="bear-off-tray">
        <button onClick={() => handleRoll(Player.One)}>One</button>
        <button onClick={() => handleRoll(Player.Two)}>Two</button>
      </div>
      <div
        className="board-left"
        onClick={() => {
          if (turn.roller === Player.One && turn.availableRolls.length === 0)
            handleRoll(Player.Two);
        }}
      >
        <div className="dice-container">
          {turn.roller === Player.Two && turn.availableRolls.length > 0 && (
            <Dice rolls={turn.availableRolls} onSwitch={handleMoveDice} />
          )}
        </div>
        <div className="point-columns">
          {boardPoints
            .filter((f) => f.id > 12 && f.id < 19)
            .map((p) => (
              <BoardPoint2
                key={`p${p.id}`}
                {...{
                  ...p,
                  direction: "down",
                  checkerColour:
                    p.owner === Player.One ? players.one.checkerColour : players.two.checkerColour,
                  onClick: (id) => {
                    const roll = getNextAvailableRoll();
                    if (roll) {
                      handleMove(id, roll.rolled);
                    }
                  }
                }}
              />
            ))}
        </div>
        <div className="point-columns-up" style={{ direction: "rtl" }}>
          {boardPoints
            .filter((f) => f.id > 6 && f.id < 13)
            .map((p) => (
              <BoardPoint2
                key={`p${p.id}`}
                {...{
                  ...p,
                  direction: "up",
                  checkerColour:
                    p.owner === Player.One ? players.one.checkerColour : players.two.checkerColour,
                  onClick: (id) => {
                    const roll = getNextAvailableRoll();
                    if (roll) {
                      handleMove(id, roll.rolled);
                    }
                  }
                }}
              />
            ))}
        </div>
      </div>
      <div
        className="bar"
        onClick={(e) => {
          const roll = getNextAvailableRoll();
          if (roll) {
            handleBarMove(roll.rolled);
          }
        }}
      >
        {Array.from({ length: bar.p1CheckerCount }).map((_c, i) => (
          <Checker id={`p24-${i + 1}`} key={`one-${i + 1}}`} colour={players.one.checkerColour} />
        ))}
        {Array.from({ length: bar.p2CheckerCount }).map((_c, i) => (
          <Checker id={`p24-${i + 1}`} key={`two-${i + 1}}`} colour={players.two.checkerColour} />
        ))}
      </div>
      <div
        className="board-right"
        onClick={() => {
          if (turn.roller === Player.Two && turn.availableRolls.length === 0)
            handleRoll(Player.One);
        }}
      >
        <div className="dice-container">
          {turn.roller === Player.One && turn.availableRolls.length > 0 && (
            <Dice rolls={turn.availableRolls} onSwitch={handleMoveDice} />
          )}
        </div>
        <div className="point-columns">
          {boardPoints
            .filter((f) => f.id > 18)
            .map((p) => (
              <BoardPoint2
                key={`p${p.id}`}
                {...{
                  ...p,
                  direction: "down",
                  checkerColour:
                    p.owner === Player.One ? players.one.checkerColour : players.two.checkerColour,
                  onClick: (id) => {
                    const roll = getNextAvailableRoll();
                    if (roll) {
                      handleMove(id, roll.rolled);
                    }
                  }
                }}
              />
            ))}
        </div>
        <div className="point-columns-up" style={{ direction: "rtl" }}>
          {boardPoints
            .filter((f) => f.id < 7)
            .map((p) => (
              <BoardPoint2
                key={`p${p.id}`}
                {...{
                  ...p,
                  direction: "up",
                  checkerColour:
                    p.owner === Player.One ? players.one.checkerColour : players.two.checkerColour,
                  onClick: (id) => {
                    const roll = getNextAvailableRoll();
                    if (roll) {
                      handleMove(id, roll.rolled);
                    }
                  }
                }}
              />
            ))}
        </div>
      </div>
      <div className="bear-off-tray">
        <div className="bear-off-tray-top">
          {Array.from({ length: bearOfTray.p2CheckerCount }).map((_c, i) => (
            <div key={`p2off-${i + 1}`} className="bear-off-checker-player-two"></div>
          ))}
        </div>
        <div className="bear-off-tray-bottom">
          {Array.from({ length: bearOfTray.p1CheckerCount }).map((_c, i) => (
            <div key={`p1off-${i + 1}`} className="bear-off-checker-player-one"></div>
          ))}
        </div>
      </div>
    </div>
  );
}

function App() {
  return <Board />;
}

export default App;
