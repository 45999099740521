export enum Player {
  None = 0,
  One = 1,
  Two = 2
}

export type TurnState = {
  roller: Player;
  rolled?: number[];
  availableRolls: AvailableRoll[];
};
export type DiceState = {
  rolls: AvailableRoll[];
  onSwitch(): void;
};

export type AvailableRoll = {
  rolled: number;
  used: boolean;
};

export type BarState = {
  p1CheckerCount: number;
  p2CheckerCount: number;
};

export type PlayerMatchState = {
  name: string;
  checkerColour: string;
  points: number;
};
export type CheckerState = {
  id: string;
  colour: string;
};

export type BoardPointState = {
  id: number;
  owner?: Player;
  checkerCount: number;
};

export interface IBoardPointUI {
  checkerColour: string;
  direction: string;
  onClick(id: number): void;
}

export enum PointStatus {
  Unknown,
  PointOffBoard,
  EmptyPoint,
  OpponentBlot,
  OwnBlot,
  OpponentPoint,
  OwnPoint
}

export type TBoardPoint = BoardPointState & IBoardPointUI;

export type BearOffTrayState = BarState;
